<template>
  <div :style="{ top: highlightBox.top + '%', left: highlightBox.left + '%' , width: highlightBox.width + '%', height: highlightBox.height + '%'}" class="highlight-box"  @click="goNext">
    <div class="content"></div>
  </div>
</template>

<script>
export default {
  props: {
    highlightBox: Object
  },
  methods: {
    goNext() {
      this.$EventBus.$emit('goNext')
    }
  }
};
</script>
  
<style>
.highlight-box {
  position: absolute;
  display: inline-block;
  border: 0.24rem solid red;
  border-radius: 4px;
  background-color: transparent;
}
.highlight-box:hover {
  cursor: pointer;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  scale: 1.01;
}
.content {
  background-color: transparent;
}
</style>
  