<template>
  <div class="page-button-content">
    <v-btn v-for="pageNumber in lastPage" :key="pageNumber" class="page-button" @click="goToPage(pageNumber)" :color="pageNumber === nowPage ? 'primary' : ''" :style="pageNumber != nowPage ? 'opacity: 0.8 !important;' : ''">
      {{ pageNumber }}
    </v-btn>
  </div>
</template>
  
<script>
export default {
  props: ['componentItem', 'nowPageNumber'],
  data() {
    return {
      lastPage: 0, 
      nowPage: this.nowPageNumber,
    };
  },
  mounted() {
    if (this.componentItem != null && this.componentItem.length > 0) {
      this.lastPage = this.componentItem.length - 1;
    }
  },
  created() {
    this.$EventBus.$on('changePage', this.changePage)
  },
  methods: {
    goToPage(pageNumber) {
        this.$EventBus.$emit('goToPage', pageNumber);
        this.changePage(pageNumber);
    },
    changePage(changePageNumber) {
        this.nowPage = changePageNumber;
    }
  }
};
</script>
  
<style>
.page-button-content {
  position: relative;
  display: flex;
  justify-content: center; 
  justify-content: end; 
  align-items: center;
}
.page-button {
  opacity: 0.8;
  min-width: 1.5rem !important;
  width: 1.5rem !important;
  height: 1.5rem !important;
  padding: 0 !important;
  border-radius: 50%;
  margin-right: 2.5px;
  margin-left: 2.5px;
}
</style>
  