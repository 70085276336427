const tab_2 = [
    {
        page: 0,
        backgroundImage: 'labeling_1.png',
        title: '학습 데이터 라벨링',
        description: ' ',
        descriptionImage: 'edu-info-2.png',   
    },
    {
        page: 1,
        backgroundImage: 'labeling_1.png',
        description: '데이터 관리 메뉴 클릭',   
        top: 30.2,
        left: 26.5,
        arrowDirection: 'left',
        highlightBox: {
            top: 27.6,
            left: 0.2,
            width: 16.1,
            height: 5.3
        }  
    },
    {
        page: 2,
        backgroundImage: 'labeling_1.png',
        description: '라벨 추가',   
        top: 36,
        left: 25,
        arrowDirection: 'up',
        highlightBox: {
            top: 20.9,
            left: 21,
            width: 8.3,
            height: 4.7
        }  
    },
    {
        page: 3,
        backgroundImage: 'labeling_2.png',
        description: '라벨 이름 입력 후 생성',
        top: 75,
        left: 50,
        arrowDirection: 'up',
        highlightBox: {
            top: 57.9,
            left: 61.85,
            width: 4.9,
            height: 4.7
        }  
    },
    {
        page: 4,
        backgroundImage: 'labeling_3.png',
        description: '라벨링 버튼 클릭',   
        top: 28,
        left: 84.3,
        arrowDirection: 'up',
        highlightBox: {
            top: 12.9,
            left: 80.6,
            width: 7.2,
            height: 4.7
        }  
    },
    {
        page: 5,
        backgroundImage: 'labeling_4.png',
        description: '라벨 클래스 클릭',  
        top: 23.6,
        left: 92.2,
        highlightBox: {
            top: 10.35,
            left: 92.8,
            width: 7.3,
            height: 4.8
        }  
    },
    {
        page: 6,
        backgroundImage: 'labeling_5.png',
        description: '라벨 선택',
        top: 18,
        left: 69, 
        arrowDirection: 'right',
        highlightBox: { 
            top: 15.3,
            left: 78.2,
            width: 22,
            height: 4.9
        }  
    },
    {
        page: 7,
        backgroundImage: 'labeling_6.png',
        description: '사각형 박스 클릭', 
        top: 37,
        left: 21, 
        arrowDirection: 'left',
    },
    {
        page: 8,
        backgroundImage: 'labeling_7.png',
        description: '드래그로 영역 지정', 
        top: 42,
        left: 54.5, 
        arrowDirection: 'left',
    },
    {
        page: 9,
        backgroundImage: 'labeling_7.png',
        description: '나머지 이미지 라벨링 작업', 
        top: 76.5,
        left: 66, 
        arrowDirection: 'right',
        highlightUnderline: { 
            top: 60,
            left: 78.2,
            width: 21.8,
            height: 40
        }
    },
    {
        page: 10,
        backgroundImage: 'labeling_8.png',
        description: '라벨링 완료', 
        top: 62,
        left: 54.5, 
        arrowDirection: 'right',
        highlightUnderline: { 
            top: 39.5,
            left: 64.7,
            width: 4.5,
            height: 58
        }  
    },
    {
        page: 11,
        backgroundImage: 'dataset_1.png',
        description: '데이터셋 생성', 
        top: 27.5,
        left: 92,
        arrowDirection: 'up',
        highlightBox: {
            top: 12.9,
            left: 88.3,
            width: 9.8,
            height: 4.7
        }  
    },
    {
        page: 12,
        backgroundImage: 'dataset_2.png',
        description: '원천 데이터 확인 후 다음', 
        top: 76.5,
        left: 78.5,
        arrowDirection: 'down',
        highlightBox: {
            top: 87.4,
            left: 76.4,
            width: 4.9,
            height: 4.7
        }  
    },
    {
        page: 13,
        backgroundImage: 'dataset_3.png',
        description: '학습 데이터 분할 확인 후 다음', 
        top: 76.5,
        left: 78.5,
        arrowDirection: 'down',
        highlightBox: {
            top: 87.4,
            left: 76.4,
            width: 4.9,
            height: 4.7
        }  
    },
    {
        page: 14,
        backgroundImage: 'dataset_4.png',
        description: '전처리 설정 확인 후 다음', 
        top: 76.5,
        left: 78.5,
        arrowDirection: 'down',
        highlightBox: {
            top: 87.4,
            left: 76.5,
            width: 4.9,
            height: 4.7
        }  
    },
    {
        page: 15,
        backgroundImage: 'dataset_5.png',
        description: '증강 설정 확인 후 다음', 
        top: 76.5,
        left: 78.5,
        arrowDirection: 'down',
        highlightBox: {
            top: 87.4,
            left: 76.5,
            width: 4.9,
            height: 4.7
        }  
    },
    {
        page: 16,
        backgroundImage: 'dataset_6.png',
        description: '데이터셋 생성', 
        top: 76.5,
        left: 78.5,
        arrowDirection: 'down',
        highlightBox: {
            top: 87.4,
            left: 76.4,
            width: 4.9,
            height: 4.7
        }   
    },
    {
        page: 17,
        backgroundImage: 'dataset_7.png',
        description: '데이터셋 생성 완료', 
    },
];

export default tab_2;