<template>
    <div class="image-wrapper">
        <div v-if="selectedItem && selectedItem.backgroundImage" class="demo-component-container">
          <div class="component-image">
            <div v-if="selectedItem.page == 0" class="image-overlay"></div>
            <img v-if="!selectedItem.excludingMockup" src="@/static/internet-header.png" class="internet-mockup">
            <img :src="getImagePath(selectedItem.backgroundImage)" class="demo-image">
          </div>
          <div>
            <highlight-underline v-if="selectedItem.highlightUnderline" :highlightUnderline="selectedItem.highlightUnderline"></highlight-underline>
            <highlight-box v-if="selectedItem.highlightBox" :highlightBox="selectedItem.highlightBox"></highlight-box>
            <div v-if="selectedItem.description || selectedItem.title" :style="messageBoxStyle" class="message-box-wrapper" :key="selectedItem.page">
              <div class="message-box-centered">
                <message-box v-if="showMessageBox" :selectedItem="selectedItem" />
                <message-tooltip v-if="showTooltip" :selectedItem="selectedItem" :lastPage="lastPage" />
              </div>
            </div>
          </div>
          <div class="page-container">
            <page-button v-if="showPageButton" class="page-btn" :componentItem="this.componentItem" :nowPageNumber="selectedItem.page"></page-button>
            <v-icon v-if="this.componentItem" class="page-icon-logo" @click="togglePageButton">mdi mdi-file-multiple-outline</v-icon>
          </div>
        </div>
    </div>
  </template>
  
  <script>
  import MessageBox from '@/components/messagebox/MessageBox.vue';
  import MessageTooltip from '@/components/messagebox/MessageTooltip.vue';
  import HighlightBox from '@/components/highlight/HighlightBox.vue';
  import HighlightUnderline from '@/components/highlight/HighlightUnderline.vue';
  import PageButton from '@/components/demo/PageButton.vue';

  export default {
    props: ['componentItem', 'selectedTabId'],
    components: {
      MessageBox,
      MessageTooltip,
      HighlightBox,
      HighlightUnderline,
      PageButton,
    },
    data() {
      return {
        showMessageBox: true,
        showTooltip: false,
        selectedItem: {},
        lastPage: '',
        showPageButton: false,
      };
    },
    created() {
      this.$EventBus.$on('goPrevious', this.goPrevious)
      this.$EventBus.$on('goNext', this.goNext)
      this.$EventBus.$on('goToPage', this.goToPage)
    },
    mounted() {
      if(this.componentItem != null) {
        this.selectedItem = this.componentItem[0];
        this.lastPage = (this.componentItem.length - 1).toString();
      }
    },
    computed: {
      messageBoxStyle() {
        const { top, left } = this.selectedItem;
        return {
            top: top ? `${top}%` : '50%',
            left: left ? `${left}%` : '50%',
        };
      },
    },
    methods: {
      getImagePath(imageName) {
          return require('@/static/screenshot/' + imageName);
      },
      goPrevious() {
        if(this.selectedItem != null && this.componentItem && this.componentItem.length > 0) {
          if(this.selectedItem.page != 0) {
            if(this.selectedItem.page === 1) {
              this.showTooltip = false;
              this.showMessageBox = true;
            }
              this.selectedItem = this.componentItem[this.selectedItem.page - 1];
          }
          this.$EventBus.$emit('changePage', this.selectedItem.page);
        }
      },
      goNext() {
        if(this.selectedItem != null && this.componentItem && this.componentItem.length > 0) {
          if(this.selectedItem.page != this.lastPage) {
            if(this.selectedItem.page === 0) {
              this.showTooltip = true;
              this.showMessageBox = false;
            }
              this.selectedItem = this.componentItem[this.selectedItem.page + 1];
          }
          this.$EventBus.$emit('changePage', this.selectedItem.page);
        }
      },
      goToPage(pageNumber) {
        if(this.selectedItem != null && this.componentItem && this.componentItem.length > 0) {
          if(pageNumber == 0 ) {
            this.showTooltip = false;
            this.showMessageBox = true;
          } else {
            this.showTooltip = true;
            this.showMessageBox = false;
          }
          this.selectedItem = this.componentItem[pageNumber];
        }
      },
      togglePageButton() {
        this.showPageButton = !this.showPageButton;
      },
    },
  };
  </script>
  
  <style>
  .demo-component-container {
    position: relative;
    width: 100%;
    border-radius: 12px;
    box-shadow: 0 3px 15px rgba(0, 0, 0, .1);
    overflow: hidden;
  }
  .image-wrapper {
    display: flex;
    align-items: center;
  }
  .image-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
  }
  .internet-mockup {
    display: block;
    box-shadow: none !important;
    border-radius: 0 !important;
    width: 100%;
    height: auto !important;
    margin: 0 !important;
    padding: 0 !important;
    object-fit: cover;
  }
  .demo-image {
    display: block;
    box-shadow: none !important;
    border-radius: 0 !important;
    width: 100%;
    object-fit: cover;
    height: auto !important;
    margin: 0 !important;
    padding: 0 !important;
  }
  .message-box-wrapper {
    width: 100%;
    position: absolute;
    transform: translate(-50%, -50%);
  }
  .message-box-centered {
    display: flex;
    justify-content: center;
    align-items: center;
    animation: fadeTop 1.5s ease;
  }
  .page-container {
    position: absolute;
    bottom: 2px;
    right: 2px;
    display: flex;
    z-index: 10;
  }
  .page-btn {
    z-index: 100;
    bottom: 0 !important; 
    right: 0 !important; 
    padding-right: 7px !important;
    padding-left: 3px !important;
  }
  .page-icon-logo {
    padding: 5px;
    border-radius: 20px;
    z-index: 100;
    position: fixed;
  }
  .page-icon-logo:hover {
    background-color: rgba(255,255,255,0.5);
  }
  @keyframes fadeTop {
  from {
    transform: translateY(-20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
  </style>
  