const tab_4 = [
    {
        page: 0,
        backgroundImage: 'home.png',
        title: '인공지능 모델 배포',
        description: ' ',
        descriptionImage: 'edu-info-5.png',   
    },
    {
        page: 1,
        backgroundImage: 'home.png',
        description: '웹 포인트 메뉴 클릭',   
        top: 36,
        left: 26.5,
        arrowDirection: 'left',
        highlightBox: {
            top: 32.9,
            left: 0.2,
            width: 16.1,
            height: 5.3
        }  
    },
    {
        page: 2,
        backgroundImage: 'webpoint_1.png',
        description: '웹 포인트 생성 클릭',    
        top: 27.5,
        left: 91.5,
        arrowDirection: 'up',
        highlightBox: {
            top: 12.9,
            left: 89.5,
            width: 8.5,
            height: 4.7
        }  
    },
    {
        page: 3,
        backgroundImage: 'webpoint_2.png',
        description: '웹 포인트 생성',
        top: 90.7,
        left: 67,
        arrowDirection: 'right',
        highlightBox: {
            top: 88.6,
            left: 76.35,
            width: 5,
            height: 4.7
        } 
    },
    {
        page: 4,
        backgroundImage: 'webpoint_3.png',
        description: '웹 포인트 생성 확인',
        top: 51,
        left: 25,
        arrowDirection: 'up',
        highlightUnderline: {
            top: 34.9,
            left: 19.7,
            width: 78,
            height: 5.4
        },
    },
    {
        page: 5,
        backgroundImage: 'webpoint_4.png',
        description: '프로젝트 메뉴 클릭',
        top: 13.4,
        left: 26.5,
        arrowDirection: 'left',
        highlightBox: {
            top: 10.6,
            left: 0.2,
            width: 16.1,
            height: 5.3
        }
    },
    {
        page: 6,
        backgroundImage: 'home.png',
        description: '프로젝트 선택',
        top: 35.2,
        left: 48,
        arrowDirection: 'left',
        highlightBox: {
            top: 22,
            left: 20,
            width: 18.8,
            height: 24.5
        }
    },
    {
        page: 7,
        backgroundImage: 'deploy_1.png',
        description: '배포 메뉴 클릭',
        top: 51.7,
        left: 26.5,
        arrowDirection: 'left',
        highlightBox: {
            top: 49,
            left: 0.2,
            width: 16.1,
            height: 5.3
        }
    },
    {
        page: 8,
        backgroundImage: 'deploy_1.png',
        description: '새로운 모델 배포 클릭',
        top: 27.5,
        left: 90.8,
        arrowDirection: 'up',
        highlightBox: {
            top: 12.9,
            left: 87,
            width: 10.7,
            height: 4.7
        }  
    },
    {
        page: 9,
        backgroundImage: 'deploy_2.png',
        description: '모델 선택 후 계속',
        top: 81.5,
        left: 79,
        arrowDirection: 'up',
        highlightBox: {
            top: 65.35,
            left: 76.4,
            width: 4.9,
            height: 4.7
        } 
    },
    {
        page: 10,
        backgroundImage: 'deploy_3.png',
        description: '배포 포인트 선택 후 계속',
        top: 81,
        left: 79,
        arrowDirection: 'up',
        highlightBox: {
            top: 65,
            left: 76.4,
            width: 4.9,
            height: 4.7
        } 
    },
    {
        page: 11,
        backgroundImage: 'deploy_4.png',
        description: '배포 시작',
        top: 88,
        left: 78.15,
        arrowDirection: 'up',
        highlightBox: {
            top: 72.46,
            left: 74.6,
            width: 6.6,
            height: 4.7
        } 
    },
    {
        page: 12,
        backgroundImage: 'deploy_5.png',
        description: '배포 완료',
        top: 73.5,
        left: 24,
        arrowDirection: 'down',
        highlightUnderline: {
            top: 84.05,
            left: 19.7,
            width: 78,
            height: 6.55
        },
    },
];

export default tab_4;