<template>
  <div class="tooltip-container">
    <div class="tooltip-content">
      <div><strong class="tooltip-title">{{ selectedItem.title }}</strong></div>
      <p class="tooltip-description">{{ selectedItem.description }}</p>
      <div class="tooltip-pagination">
        <span>
          <v-btn class="previous-button" @click="goPrevious">
            <v-icon class="previous-icon">mdi-arrow-left</v-icon>
          </v-btn>
          <span class="paging"><span>{{ selectedItem.page }}</span>/<span>{{ lastPage }}</span></span>
        </span>
        <span class="button-space"></span>
        <span class="next-button-container">
          <v-btn v-if="this.selectedItem.page == this.lastPage" color="primary" class="next-button" @click="complete">완료</v-btn>
          <v-btn v-else color="primary" class="next-button" @click="goNext">다음</v-btn>
        </span>
      </div>
      <div v-if="this.selectedItem.arrowDirection" class="arrow" :id="this.selectedItem.arrowDirection"></div> 
    </div>
  </div>
</template>

<script>
export default {
  props: {
    selectedItem: Object,
    lastPage: String,
  },
  methods: {
    goPrevious() {
      this.$EventBus.$emit('goPrevious')
    },
    goNext() {
      this.$EventBus.$emit('goNext')
    },
    complete() {
      this.$EventBus.$emit('complete')
    }
  }
};
</script>

<style>
.tooltip-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.tooltip-content {
  position: absolute;
  background-color: rgba(255, 255, 255, 1);
  color: #000;
  padding: 1.13% 1%;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 2px 7px rgba(0, 0, 0, 0.2), 0px 2px 4px -1px rgba(0, 0, 0, 0.08), 0px 4px 5px 0px rgba(0, 0, 0, 0.06), 0px 1px 10px 0px rgba(0, 0, 0, 0.05);
}
.arrow {
  position: absolute;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid rgba(255, 255, 255, 1);
  filter: drop-shadow(0 2px 2px rgba(0, 0, 0, 0.15));
}
#up {
  top: -0.5rem;
  left: 50%;
  transform: translateX(-50%) rotate(180deg);
}
#down {
  bottom: -0.5rem;
  left: 50%;
  transform: translateX(-50%);
}
#left {
  top: 50%;
  left: -14px;
  transform: translateY(-50%) rotate(90deg);
}
#right {
  top: 50%;
  right: -14px;
  transform: translateY(-50%) rotate(-90deg);
}
.tooltip-title {
  display: block !important;
  margin-bottom: 0 !important;
  font-size: 1.15vw;
  font-weight: bold;
}
.tooltip-description {
  padding-top: 0.18vw;
  font-size: 0.8vw;
  margin-bottom: 0.52vw !important;
  padding-right: 0.17vw !important;
  padding-left: 0.17vw !important;
}
.tooltip-pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.previous-button {
  color: gray !important; 
  min-width: 1.7rem !important;
  max-height: 1.7rem !important;
  border-radius: 50%;
  padding: 0 !important;
  margin: 0 !important;
  background-color: transparent !important;
  box-shadow: none !important;
  height: 1.9rem !important;
}
.previous-icon {
  font-size: 0.8vw !important;
  padding-top: 0.05vw;
}
.paging {
  color: gray !important; 
  font-size: 0.65vw;
  margin-right: 1vw;
}
.next-button-container {
  display: flex;
  align-items: center;
}
.next-button {
  min-width: auto !important;
  padding: 0.4rem 0.7rem !important;
  max-height: 5.5vw !important;
  height: auto !important;
  min-height: 1vw;
  font-size: 0.65vw !important;
}
@keyframes fromTop {
  from {
    transform: translateY(-20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
</style>
