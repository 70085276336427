import tab_1 from '@/data/tab_1.js';
import tab_2 from '@/data/tab_2.js';
import tab_3 from '@/data/tab_3.js';
import tab_4 from '@/data/tab_4.js';
import tab_5 from '@/data/tab_5.js';

const tabs = [
    {
        id: 1,
        label: '데이터 관리',
        description: '클라우드 스토리지를 통해 인공지능 개발을 위한 학습 데이터를 간편하게 업로드하고 관리할 수 있습니다. 또한 API를 통해 개발 중인 프로젝트에 연동하여 자동으로 학습용 데이터를 업로드할 수 있습니다.',
        descriptionImage: 'info-dataset.png',
        data: tab_1
    },
    {
        id: 2,
        label: '데이터 라벨링',
        description: '웹 기반 라벨링 도구를 통해 학습 데이터 셋에 업로드된 이미지 및 정형데이터의 어노테이션 정보를 생성할 수 있습니다. 다수의 작업자들이 한 프로젝트에서 실시간으로 같이 라벨링을 참여할 수 있으며, 추론 서버와 연동하여 자동 라벨링을 통한 빠르게 어노테이션 정보를 생성할 수 있습니다.',
        descriptionImage: 'info-label.png',
        data: tab_2
    },
    {
        id: 3,
        label: '인공지능 학습',
        description: '클라우드 학습 서버 또는 사용자가 생성한 커스텀 학습 서버를 통해 클라우드 환경에서 쉽게 인공지능 학습을 할 수 있습니다. 학습이 끝나면 버전 별로 평가 정보와 모델을 테스트할 수 있는 인터페이스를 사용할 수 있습니다.',
        descriptionImage: 'info-training.png',
        data: tab_3
    },
    {
        id: 4,
        label: '인공지능 배포',
        description: '학습한 AI 모델을 엣지 디바이스 S/W(엣지 포인트) 또는 웹 API 서버(웹 포인트) 두 가지 형태로 배포할 수 있습니다.',
        descriptionImage: 'info-deploy.png',
        data: tab_4
    },
    {
        id: 5,
        label: '테스트 및 구현',
        description: '적용된 모델의 사용 데이터를 수집하여 통계 분석 도구를 사용할 수 있습니다. 또한 배포된 모델을 웹에서 테스트할 수 있는 인터페이스를 제공합니다.',
        descriptionImage: 'info-test.png',
        data: tab_5
    },
  ];
  
  export default tabs;