<template>
  <v-app class="container">
    <header-layout></header-layout>
    <v-main>
      <v-container class="main-container">
        <v-row>
          <v-col cols="12" md="4"></v-col>
          <v-col cols="12" md="8">
            <div>
              <v-btn
                v-for="tab in tabs"
                :key="tab.id"
                :class="['demo-tab', { 'active': tab.id === selectedTab.id }]"
                :color="tab.id === selectedTab.id ? 'primary' : ''"
                @click="selectTab(tab)">
                {{ tab.label }}
              </v-btn>
            </div>
          </v-col>
          <v-col cols="12" md="4">
            <div class="text-content" :key="selectedTab.id">
              <div class="tab-label">{{ selectedTab.label }}</div>
              <div class="tab-description">{{ selectedTab.description }}</div>
              <img v-if="selectedTab.descriptionImage" :src="getImagePath(selectedTab.descriptionImage)" class="tab-image">
            </div>
          </v-col>
          <v-col cols="12" md="8">
            <div class="demo-component" :key="selectedTab.id">
              <demo-component :componentItem="selectedTab.data" :selectedTabId="selectedTab.id"></demo-component>
            </div>
            <div class="homepage-link">
              <a href="https://app.vridgeai.com/" target="_blank" class="go-vridge">
                <img src="@/static/logo/vridge-logo.png" class="go-vridge-icon" >
                <span class="go-vridge-text">브릿지 바로가기</span>
              </a>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import HeaderLayout from '@/components/header/HeaderLayout.vue'
import tabs from '@/data/tabs.js';
import DemoComponent from '@/components/demo/DemoComponent.vue'

export default {
  components: {
    HeaderLayout,
    DemoComponent,
  },
  data() {
    return {
      tabs: tabs,
      selectedTab: {},
    };
  },
  created() {
    this.$EventBus.$on('complete', this.complete)
  },
  mounted() {
    this.selectedTab = this.tabs[0];
  },
  methods: {
    selectTab(tab) {
      if (this.selectedTab != tab) {
        this.selectedTab = tab;
      } else {
        this.$EventBus.$emit('goToPage', 0);
      }
      
    },
    getImagePath(imageName) {
      if (imageName) {
        return require(`@/static/${imageName}`);
      }
    },
    complete() {
      this.selectedTab = this.tabs[this.tabs.indexOf(this.selectedTab) + 1];
    }
  }
};
</script>

<style>
.main-container {
  padding: 3px !important;
  width: 100% !important;
}
.demo-tab {
  margin-right: 10px;
  margin-top: 5px;
  font-weight: 500;
  border-radius: 20px;
  background-color: transparent !important;
  box-shadow: none !important;
}
.demo-tab.active {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12) !important;
}
.tab-label {
  color: #333;
  font-size: 1.46vw;
  font-family: 'Pretendard-Bold';
  line-height: 1.5;
  margin-bottom: 18px;
  margin-top: 10px;
}
.tab-description {
  font-size: 0.84vw;
}
.tab-image {
  margin-top: 30px;
  text-align: center;
  width: 100%;
  max-width: 100%;
}
.demo-component {
  animation: fadeIn 0.7s ease;
}
.text-content {
  margin-right: 37px;
  animation: fadeLeft 0.2s ease;
}
.homepage-link {
  display: inline-block;
  position: fixed;
  right: 1.7%;
  bottom: 3%;
}
.go-vridge-icon {
  height: 31px;
  width: auto;
}
.go-vridge-text {
  padding-right: 10px;
  font-size: 0.88rem;
}
.go-vridge {
  text-decoration: none;
  color: rgba(0, 0, 0, 1) !important;
  border: rgba(0,0,0,0.1);
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.18), 0px 4px 5px 0px rgba(0, 0, 0, 0.08), 0px 1px 10px 0px rgba(0, 0, 0, 0.06) !important;
  padding: 1.2px 6px;
  border-radius: 20px !important;
  display: flex;
  align-items: center;
}
@keyframes fadeIn {
  from {
    transform: scale(0.6);
    opacity: 0.3;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes fadeLeft {
  from {
    transform: translateX(-10px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}
</style>
