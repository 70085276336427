const tab_5 = [
    {
        page: 0,
        backgroundImage: 'test_1.png',
        title: '모델 테스트 및 구현',
        description: ' ',
        descriptionImage: 'edu-info-4.png',   
    },
    {
        page: 1,
        backgroundImage: 'test_1.png',
        description: '모델 메뉴 클릭', 
        top: 46,
        left: 26.5,
        arrowDirection: 'left',
        highlightBox: {
            top: 43.6,
            left: 0.2,
            width: 16.1,
            height: 5.3
        }    
    },
    {
        page: 2,
        backgroundImage: 'test_1.png',
        description: '연결된 웹 포인트 클릭',
        top: 85,
        left: 41,
        arrowDirection: 'down',
        highlightUnderline: {
            top: 90,
            left: 21,
            width: 25,
            height: 10
        },
        highlightBox: {
            top: 96,
            left: 38.15,
            width: 5.9,
            height: 4
        }  
    },
    {
        page: 3,
        backgroundImage: 'test_2.png',
        description: '실험 메뉴 클릭',
        top: 51.5,
        left: 26,
        arrowDirection: 'down',
        highlightBox: {
            top: 63,
            left: 23.7,
            width: 4.7,
            height: 4.7
        }  
    },
    {
        page: 4,
        backgroundImage: 'test_3.png',
        description: '테스트 이미지 선택',
        top: 58,
        left: 48.5,
        arrowDirection: 'down',
        highlightBox: {
            top: 69,
            left: 38.8,
            width: 20,
            height: 5.3
        }  
    },
    {
        page: 5,
        backgroundImage: 'test_4.png',
        description: '테스트 라벨 선택',
        top: 68.5,
        left: 39.7,
        arrowDirection: 'up',
        highlightBox: {
            top: 51,
            left: 33.3,
            width: 12.5,
            height: 7
        },
    },
    {
        page: 6,
        backgroundImage: 'test_5.png',
        description: '모델 테스트 시작',
        top: 33,
        left: 24.5,
        arrowDirection: 'up',
        highlightBox: {
            top: 17.5,
            left: 22.5,
            width: 3.7,
            height: 4.7
        },
    },
    {
        page: 7,
        backgroundImage: 'test_6.png',
        description: '모델 테스트 진행중',
        top: 19,
        left: 50,
        arrowDirection: 'down',
        highlightUnderline: {
            top: 21,
            left: 19.3,
            width: 79.3,
            height: 79
        },
    },
    {
        page: 8,
        backgroundImage: 'test_7.png',
        description: '통계 메뉴 클릭',
        top: 51.5,
        left: 21.5,
        arrowDirection: 'down',
        highlightBox: {
            top: 63,
            left: 19.1,
            width: 4.7,
            height: 4.7
        }  
    },
    {
        page: 9,
        backgroundImage: 'test_8.png',
        description: '테스트 결과 확인',
        top: 15,
        left: 59,
        arrowDirection: 'down',
        highlightUnderline: {
            top: 25.5,
            left: 19.3,
            width: 79.3,
            height: 74.5
        },
    },
    {
        page: 10,
        backgroundImage: 'test_9.png',
        description: '모델 구현',
        top: 65.1,
        left: 52.5,
        arrowDirection: 'left',
        highlightBox: {
            top: 63,
            left: 38.1,
            width: 4.7,
            height: 4.7
        },
        highlightUnderline: {
            top: 30,
            left: 20.5,
            width: 50,
            height: 17
        },
    },
    {
        page: 11,
        backgroundImage: 'postman_1.png',
        description: 'POSTMAN에서 API 테스트',
        excludingMockup: 'exclud',
    },
    // {
    //     page: 12,
    //     backgroundImage: 'postman_2.png',
    //     description: 'URL 입력',
    //     top: 32,
    //     left: 23,
    //     arrowDirection: 'up',
    //     excludingMockup: 'exclud',
    //     highlightUnderline: {
    //         top: 16.4,
    //         left: 6.7,
    //         width: 81.6,
    //         height: 4.9
    //     },
    // },
    {
        page: 12,
        backgroundImage: 'postman_2_2.png',
        description: 'URL 입력',
        top: 19,
        left: 50,
        arrowDirection: 'left',
        excludingMockup: 'exclud',
    },
    {
        page: 13,
        backgroundImage: 'postman_3.png',
        description: '브릿지에서 API 키 발급 후 입력',
        top: 64,
        left: 52,
        arrowDirection: 'up',
        excludingMockup: 'exclud',
        highlightUnderline: {
            top: 35.7,
            left: 30,
            width: 44.3,
            height: 18
        },
    },
    {
        page: 14,
        backgroundImage: 'postman_4_1.png',
        description: 'Body에 요청 본문 작성',
        top: 12,
        left: 50,
        arrowDirection: 'down',
        excludingMockup: 'exclud',
        highlightUnderline: {
            top: 21.6,
            left: 6.2,
            width: 90.5,
            height: 49.7
        },
    },
    {
        page: 15,
        backgroundImage: 'postman_4.png',
        description: 'API 요청하기',
        top: 19,
        left: 79.3,
        arrowDirection: 'right',
        excludingMockup: 'exclud',
        highlightBox: {
            top: 16.5,
            left: 88.6,
            width: 7.5,
            height: 4.95
        },
    },
    {
        page: 16,
        backgroundImage: 'postman_5.png',
        description: 'API 응답 확인',
        top: 14.5,
        left: 50,
        arrowDirection: 'down',
        excludingMockup: 'exclud',
        highlightUnderline: {
            top: 26.1,
            left: 6,
            width: 90.5,
            height: 71.7
        },
    },
];

export default tab_5;