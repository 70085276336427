const tab_3 = [
    {
        page: 0,
        backgroundImage: 'learning_1.png',
        title: '인공지능 학습',
        description: ' ',
        descriptionImage: 'edu-info-3.png',   
    },
    {
        page: 1,
        backgroundImage: 'learning_1.png',
        description: '학습 메뉴 클릭',   
        top: 40.4,
        left: 26.5,
        arrowDirection: 'left',
        highlightBox: {
            top: 38.2,
            left: 0.2,
            width: 16.1,
            height: 5.3
        } 
    },
    {
        page: 2,
        backgroundImage: 'learning_1.png',
        description: '새로운 모델 학습 클릭',   
        top: 27.5,
        left: 90.8,
        arrowDirection: 'up',
        highlightBox: {
            top: 12.9,
            left: 87.2,
            width: 10.5,
            height: 4.7
        }  
    },
    {
        page: 3,
        backgroundImage: 'learning_2.png',
        description: '데이터셋 선택 후 계속',
        top: 81.5,
        left: 79,
        arrowDirection: 'up',
        highlightBox: {
            top: 65.35,
            left: 76.4,
            width: 4.9,
            height: 4.7
        } 
    },
    {
        page: 4,
        backgroundImage: 'learning_3.png',
        description: '학습 모델 선택 후 계속',
        top: 90.7,
        left: 66,
        arrowDirection: 'right',
        highlightUnderline: {
            top: 34.65,
            left: 34.9,
            width: 44.5,
            height: 4.7
        },
        highlightBox: {
            top: 88.6,
            left: 76.35,
            width: 5,
            height: 4.7
        } 
    },
    {
        page: 5,
        backgroundImage: 'learning_4.png',
        description: '학습 서버 선택 후 계속',
        top: 81.5,
        left: 79,
        arrowDirection: 'up',
        highlightBox: {
            top: 65.35,
            left: 76.4,
            width: 4.9,
            height: 4.7
        } 
    },
    {
        page: 6,
        backgroundImage: 'learning_5.png',
        description: '학습 시작',
        top: 88,
        left: 78.15,
        arrowDirection: 'up',
        highlightBox: {
            top: 72.1,
            left: 74.8,
            width: 6.4,
            height: 4.7
        } 
    },
    {
        page: 7,
        backgroundImage: 'learning_6.png',
        description: '학습 시작 확인',
        top: 71.8,
        left: 64.5,
        arrowDirection: 'up',
        highlightBox: {
            top: 57,
            left: 62,
            width: 4.9,
            height: 4.7
        } 
    },
    {
        page: 8,
        backgroundImage: 'learning_7.png',
        description: '학습 아이디 클릭',
        top: 73,
        left: 31,
        arrowDirection: 'down',
        highlightBox: {
            top: 84.6,
            left: 28.2,
            width: 6,
            height: 5.4
        } 
    },
    {
        page: 9,
        backgroundImage: 'learning_8.png',
        description: '실시간 학습 평가 모니터링',
        top: 12.5,
        left: 45.7,
        arrowDirection: 'down',
        highlightUnderline: {
            top: 21,
            left: 19.6,
            width: 52.2,
            height: 79
        },
    },
    {
        page: 10,
        backgroundImage: 'learning_9.png',
        description: '학습 결과 확인',
        top: 12.5,
        left: 45.7,
        arrowDirection: 'down',
        highlightUnderline: {
            top: 21,
            left: 19.6,
            width: 52.2,
            height: 79
        },
    },
    {
        page: 11,
        backgroundImage: 'learning_10.png',
        description: '학습 결과 확인',
        top: 55,
        left: 82,
        arrowDirection: 'left',
        highlightUnderline: {
            top: 13,
            left: 19.7,
            width: 52.2,
            height: 85.1
        },
    },
];

export default tab_3;