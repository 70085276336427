const tab_1 = [
    {
        page: 0,
        backgroundImage: 'project.png',
        title: '학습 데이터셋 관리',
        description: ' ', 
        descriptionImage: 'edu-info-1.png',    
    },
    {
        page: 1,
        backgroundImage: 'project.png',
        description: '데이터 관리 메뉴 클릭',   
        top: 30.2,
        left: 26.5,
        arrowDirection: 'left',
        highlightBox: {
            top: 27.6,
            left: 0.2,
            width: 16.1,
            height: 5.3
        }
    },
    {
        page: 2,
        backgroundImage: 'upload_1.png',
        description: '이미지 추가 버튼 클릭',   
        top: 27.5,
        left: 75.5,
        arrowDirection: 'up',
        highlightBox: {
            top: 12.9,
            left: 71.2,
            width: 8.8,
            height: 4.7
        }  
    },
    {
        page: 3,
        backgroundImage: 'upload_2.png',
        description: '파일 첨부',
        top: 85,
        left: 50,
        arrowDirection: 'up',
    },
    {
        page: 4,
        backgroundImage: 'upload_3.png',
        description: '업로드 클릭',
        top: 77,
        left: 72,
        arrowDirection: 'down',
        highlightBox: {
            top: 88.4,
            left: 68.3,
            width: 7.5,
            height: 4.7
        }
    },
    {
        page: 5,
        backgroundImage: 'upload_4.png',
        description: '업로드 확인',
        top: 77.5,
        left: 64.5,
        arrowDirection: 'up',
        highlightBox: {
            top: 60.9,
            left: 62,
            width: 4.9,
            height: 4.7
        }
    },
    {
        page: 6,
        backgroundImage: 'upload_5.png',
        description: '데이터 업로드 완료',
    },
];

export default tab_1;