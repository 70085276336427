<template>
  <div :style="{ top: highlightUnderline.top + '%', left: highlightUnderline.left + '%' , width: highlightUnderline.width + '%', height: highlightUnderline.height + '%'}" class="highlight-underline">
    <div class="underline-content"></div>
  </div>
</template>
  
<script>
export default {
  props: {
    highlightUnderline: Object
  },
};
</script>
  
<style>
.highlight-underline {
  position: absolute;
  display: inline-block;
  border: 0.24rem solid rgba(24, 118, 210, 0.5);
  background-color: rgba(24, 118, 210, 0.03);
}
.highlight-underline:hover {
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
}
</style>
  