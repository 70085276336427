<template>
  <div class="demo-message-box">
    <div><strong class="message-box-title">{{ selectedItem.title }}</strong></div>
    <p v-if="selectedItem.description" class="message-box-description">{{ selectedItem.description }}</p>
    <img :src="getImagePath(selectedItem.descriptionImage)" class="message-image">
    <div class="message-pagination">
      <v-btn color="primary" class="start-button" @click="goNext">시작하기</v-btn>
    </div>
  </div>
</template>
  
<script>
export default {
  props: ['selectedItem'],
  methods: {
    goNext() {
      this.$EventBus.$emit('goNext')
    },
    getImagePath(imageName) {
      if (imageName) {
        return require(`@/static/${imageName}`);
      }
    },
  }
};
</script>
  
<style>
.demo-message-box {
  width: 36%;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 2vw 1vw 1.7vw 1vw;
  border-radius: 7px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.message-box-title {
  display: block !important;
  margin-bottom: 0 !important;
  font-size: 1.15vw;
}
.message-box-description {
  padding-top: 0.2vw;
  font-size: 0.8vw !important;
  margin: 0;
}
.message-pagination {
  flex: 1;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.message-image {
  text-align: center;
  max-width: 100%;
  width: 8vw;
  min-width: 60px;
  margin: 0 !important;
}
.start-button {
  min-width: auto !important;
  margin-top: 1vw; 
  font-size: 0.68vw !important;
  padding: 0.5rem 0.7rem !important;
  min-height: 1vw !important;
  max-height: 7vw !important;
  height: auto !important;
}
@keyframes fromTop {
  from {
    transform: translateY(-20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
</style>
  